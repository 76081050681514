<template>
  <div class="page-body">
    <Header />

    <main class="main">
      <router-view :key="$route.fullPath" />
    </main>
  </div>

  <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
}
</script>
